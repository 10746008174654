import { skillsUrl } from "js/api/settings";
import MyLoader from "components/layout/MyLoader/MyLoader";
import Error from "components/layout/Error";
import useApi from "hooks/useApi";
import { ERROR_OCCURED, NO_SKILLS } from "js/constants/messages";
import NoData from "components/layout/NoData";

const SkillsList = () => {
  const { properties, loading, error } = useApi(skillsUrl);

  if (loading) return <MyLoader loaderType="ball-scale-ripple-multiple" />;

  if (error) {
    return <Error>{ERROR_OCCURED}</Error>;
  }

  if (!properties) return <NoData color="black">{NO_SKILLS}</NoData>;

  const skillList = properties[0].attributes.skill;

  return (
    <ul>
      {skillList.map((item) => {
        return <li key={item}>{item}</li>;
      })}
    </ul>
  );
};

export default SkillsList;
