import Header from "components/typography/Header";
import HeroImage from "../HeroImage";
import Quotes from "../Quotes";
import PropTypes from "prop-types";
import useApi from "hooks/useApi";
import MyLoader from "components/layout/MyLoader/MyLoader";
import { heroUrl } from "js/api/settings";

const Intro = ({ title, quoteRight, quoteLeft }) => {
  const { heroImage, error, loading } = useApi(heroUrl);

  if (loading) return <MyLoader loaderType="ball-scale-ripple-multiple" />;

  return (
    <section className="intro content">
      <Header>{title}</Header>
      <div className="image-container">
        <HeroImage heroImage={heroImage} eror={error}/>
        <Quotes right={quoteRight} left={quoteLeft} />
      </div>
    </section>
  );
};

Intro.propTypes = {
  quoteRight: PropTypes.string,
  quoteLeft: PropTypes.string,
};

export default Intro;
