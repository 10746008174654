import Paragraph from "components/typography/Paragraph";

const ContactByEmail = () => {
  return (
    <Paragraph fontSize={1.1}>
      Contact me by
      <a href="mailto:glenn_lar@hotmail.com?subject=Portfolio | Contact Form">
        {" "}
        email{" "}
      </a>
      or use the form below.
    </Paragraph>
  );
};

export default ContactByEmail;
