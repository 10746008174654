import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGraduate,
  faCode,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EducationList from "../lists/EducationList";
import ExperienceList from "../lists/ExperienceList";
import SkillsList from "../lists/SkillsList";

const TabsContent = () => {
  return (
    <Tabs defaultActiveKey="one">
      <Tab
        eventKey="one"
        title={
          <>
            {<FontAwesomeIcon icon={faUserGraduate} />}
            {"Education"}
          </>
        }
      >
        <EducationList />
      </Tab>
      <Tab
        eventKey="two"
        title={
          <>
            {<FontAwesomeIcon icon={faBriefcase} />}
            {"Experience"}
          </>
        }
      >
        <ExperienceList />
      </Tab>
      <Tab
        eventKey="three"
        title={
          <>
            {<FontAwesomeIcon icon={faCode} />}
            {"My Skills"}
          </>
        }
      >
        <SkillsList />
      </Tab>
    </Tabs>
  );
}

export default TabsContent;
