import React from "react";
import { TypeAnimation } from "react-type-animation";

function TypingAnimation({ text }) {
  return (
    <TypeAnimation
      // Same String at the start will only be typed once, initially
      sequence={[text, 2000]}
      speed={50} // Custom Speed from 1-99 - Default Speed: 40
      style={{ fontSize: "inherit" }}
      wrapper="div" // Animation will be rendered as a <span>
      repeat={Infinity} // Repeat this Animation Sequence infinitely
      cursor={true}
    />
  );
}

export default TypingAnimation;
