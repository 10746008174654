import Container from "components/layout/Container";
import Head from "components/layout/Head";
import Carousel from "components/projects/Carousel";

const Projects = () => {
  return (
    <>
      <Head
        page="My Projects"
        description="View all of my previous projects including images and specifications"
      />
      <Container>
        <section className="projects">
          <Carousel />
        </section>
      </Container>
    </>
  );
};

export default Projects;
