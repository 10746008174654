import Error from "components/layout/Error";
import { ERROR_OCCURED } from "js/constants/messages";

const HeroImage = ({heroImage, error}) => {

  if (error) return <Error>{ERROR_OCCURED}</Error>;

  if (heroImage) {
    return (
      <img
        src={heroImage.attributes.image.data.attributes.url}
        alt="Portrait of me"
      ></img>
    );
  }
  return <div>Image unavailable</div>;
};

export default HeroImage;
