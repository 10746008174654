import PropTypes from "prop-types";

const DownloadCV = ({ cvEnglish }) => {
  return (
    <div className="cv">
      <a href={cvEnglish} download="Glenn-Larsen-CV-English">
        Download CV
      </a>
    </div>
  );
}

DownloadCV.propTypes = {
  cvEnglish: PropTypes.string
};

export default DownloadCV;
