import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate, faCode, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import EducationList from "../lists/EducationList";
import ExperienceList from "../lists/ExperienceList";
import SkillsList from "../lists/SkillsList";

const AccordionContent = () => {
  return (
    <Accordion className="accordion-flush">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <FontAwesomeIcon icon={faUserGraduate} />
          Education
        </Accordion.Header>
        <Accordion.Body>
          <EducationList />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <FontAwesomeIcon icon={faBriefcase} />
          Experience
        </Accordion.Header>
        <Accordion.Body>
          <ExperienceList />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <FontAwesomeIcon icon={faCode} />
          My Skills
        </Accordion.Header>
        <Accordion.Body>
          <SkillsList />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionContent;
