export const NO_EDUCATION = "No Education to show";
export const NO_EXPERIENCE = "No Experience to show";
export const NO_SKILLS = "No Skills to show";
export const ERROR_OCCURED = "An error occured";

export const NAME_SHORT = "Name must be at least 2 characters";
export const NAME_REQUIRED = "Name is required";
export const EMAIL_INVALID = "Email is invalid";
export const EMAIL_REQUIRED = "Email is required";
export const MESSAGE_SHORT = "Message must be at least 9 characters";
export const SUCCESS = "Thanks for your message! I'll be in touch soon.";
export const MESSAGE_REQUIRED = "Message is required";