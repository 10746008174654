import { useState, useEffect } from "react";
import axios from "axios";

const useApi = (url) => {
  const [properties, setProperties] = useState([]);
  const [heroImage, setHeroImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);

  const toggleItem = (project) => {
    setData(project);
    setShow(project !== null);
  };

  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(url);
        const data = response.data.data;
        setProperties(data);
        setHeroImage(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData(url);
  }, [url]);

  return {
    properties,
    heroImage,
    loading,
    error,
    data,
    show,
    toggleItem,
    closeModal,
  };
};

export default useApi;
