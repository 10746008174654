import PropTypes from "prop-types";

const Quotes = ({ left, right }) => {
  return (
    <>
      <div className="quotes-right">{right}</div>
      <div className="quotes-left">{left}</div>
    </>
  );
};

Quotes.propTypes = {
  right: PropTypes.string,
  left: PropTypes.string,
};

export default Quotes;
