import MyLoader from "components/layout/MyLoader/MyLoader";
import Slider from "react-slick";

import ModalBox from "../ModalBox";
import { projectsUrl } from "js/api/settings";
import useApi from "hooks/useApi";
import Error from "components/layout/Error";
import { settings } from "./settings";
import { ERROR_OCCURED } from "js/constants/messages";
import NoData from "components/layout/NoData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "components/typography/Header";

const Carousel = () => {
  const { properties, loading, error, data, show, toggleItem, closeModal } =
    useApi(projectsUrl);

  if (loading) return <MyLoader loaderType="ball-scale-ripple-multiple" />;

  if (error) {
    return <Error>{ERROR_OCCURED}</Error>;
  }

  if (!properties) return <NoData color="white">No projects yet!</NoData>;

  return (
    <>
      <Header>My Projects</Header>
      <div className="carousel">
        <Slider {...settings}>
          {properties.map((project) => {
            const { title, type, image } = project.attributes;
            return (
              <div key={project.id} onClick={() => toggleItem(project)}>
                <div
                  className="project"
                  style={{
                    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${image.data.attributes.url}) 50% 0% / cover no-repeat`,
                  }}
                >
                  <div className="hover-overlay">
                    <h2>{title}</h2>
                    <h3>{type}</h3>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        {show === true && data !== null && (
          <ModalBox data={data} closeModal={() => closeModal()} />
        )}
      </div>
    </>
  );
};

export default Carousel;
