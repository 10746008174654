import Head from "components/layout/Head";
import AboutMe from "components/about/AboutMe";
import AccordionContent from "components/about/AccordionContent";
import TabsContent from "components/about/TabsContent";
import Header from "components/typography/Header";
import Container from "components/layout/Container";
import myCvEnglish from "content/Glenn-Larsen-CV-English.pdf";

const About = () => {
  return (
    <>
      <Head
        page="About"
        description="Short about me, my experience and education. Download my CV"
      />
      <Container>
        <section className="about content">
          <Header>About Me</Header>
          <AboutMe cvEnglish={myCvEnglish} />
          <AccordionContent />
          <TabsContent />
        </section>
      </Container>
    </>
  );
};

export default About;
