import PropTypes from "prop-types";

const Paragraph = ({ children, fontSize }) => {
  return <p style={{ fontSize: fontSize + "rem" }}>{children}</p>;
};

Paragraph.propTypes = {
  children: PropTypes.any,
  fontSize: PropTypes.number,
};

export default Paragraph;
