import { experienceUrl } from "js/api/settings";
import MyLoader from "components/layout/MyLoader/MyLoader";
import Error from "components/layout/Error";
import useApi from "hooks/useApi";
import { ERROR_OCCURED, NO_EXPERIENCE } from "js/constants/messages";
import NoData from "components/layout/NoData";

const ExperienceList = () => {
  const { properties, loading, error } = useApi(experienceUrl);

  if (loading) return <MyLoader loaderType="ball-scale-ripple-multiple" />;

  if (error) {
    return <Error>{ERROR_OCCURED}</Error>;
  }

  if (!properties) return <NoData color="black">{NO_EXPERIENCE}</NoData>;

  return (
    <ul>
      {properties.map((item) => {
        const { job, year } = item.attributes;
        return (
          <li key={item.id}>
            {job}
            <br />
            <span>{year}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default ExperienceList;
