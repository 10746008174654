import Loader from "react-loaders";

const MyLoader = ({loaderType}) => {
  return (
    <div className="loader-container">
      <Loader type={loaderType} />
    </div>
  );
};

export default MyLoader;
