import Paragraph from "components/typography/Paragraph";
import { Modal } from "react-bootstrap";

const ModalBox = ({ data, closeModal }) => {
  const { title, type, git, deployed, about, tools } = data.attributes;
  const image = data.attributes.image.data.attributes.url;

  let toolList = [];
  tools.forEach((tool) => {
    toolList.push(<li key={tool}>{tool}</li>);
  });

  return (
    <>
      <div className="overlay" onClick={closeModal}>
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="project-modal">
            <div
              className="modal-content "
              onClick={(e) => e.stopPropagation()}
            >
              <Modal.Header>
                <h4 className="modal-title" id="modalLabel">
                  {title}
                </h4>
                <span className="modal-title" id="modalLabel">
                  {type}
                </span>
                <div className="project-links">
                  <a href={git}>GIThub Link</a>
                  <a href={deployed}>Live Site</a>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <a href={deployed}>
                    <img src={image} alt={title}></img>
                  </a>
                </div>
                <div className="project-info">
                  <div>
                    <h5>About this Project</h5>
                    <Paragraph>{about}</Paragraph>
                  </div>
                  <div>
                    <h5>Built With</h5>
                    <ul>{toolList}</ul>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalBox;
