import PropTypes from "prop-types";
import TypingAnimation from "components/typography/TypingAnimation";

const Header = ({ children }) => {
  return (
    <h1>
      <TypingAnimation text={children} />
    </h1>
  );
};

Header.propTypes = {
  children: PropTypes.string,
};

export default Header;
