import Paragraph from "components/typography/Paragraph";
import DownloadCV from "../DownloadCV";
import PropTypes from "prop-types";

const AboutMe = ({ cvEnglish }) => {
  return (
    <>
      <Paragraph fontSize={1.1}>
        I am a 32 year old man born and raised in Bergen, Norway. I graduated as
        a frontend developer from Noroff school of technology in october 2022. I
        have been working part time for SIXT car rental while studying. I am
        currently studying Back-end Development and looking for a job as a
        developer.
      </Paragraph>
      <DownloadCV cvEnglish={cvEnglish} />
    </>
  );
};

AboutMe.propTypes = {
  cvEnglish: PropTypes.string,
};

export default AboutMe;
