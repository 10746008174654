import { Helmet } from "react-helmet";

const Head = ({ page, description }) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <meta charSet="utf-8" />
      <title>Portfolio | {page}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:title" content={`Portfolio | ${page}`} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content="Glenn Larsen" />
      <meta name="twitter:title" content={`Portfolio | ${page}`} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
};

export default Head;
