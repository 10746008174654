import Container from "components/layout/Container";
import Intro from "../../components/home/Intro";
import Head from "../../components/layout/Head";

const Home = () => {
  return (
    <>
      <Head
        page="Home"
        description="My Frontend developer Portfolio - Glenn Larsen"
      />
      <Container>
        <Intro
          title="My name is Glenn Larsen, I am a Frontend Developer"
          quoteRight="“Failure is an option here. If things are not failing, you are not
        innovating enough.”"
          quoteLeft="“I could either watch it happen or be a part of it.”"
        />
      </Container>
    </>
  );
};

export default Home;
