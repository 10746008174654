import Head from "components/layout/Head";
import ContactByEmail from "components/contact/ContactByEmail";
import ContactForm from "components/contact/ContactForm";
import Header from "components/typography/Header";
import Container from "components/layout/Container";

const Contact = () => {
  return (
    <>
      <Head
        page="Contact"
        description="Contact me to hear more about my portfolio or just for a casual talk"
      />
      <Container>
        <section className="contact content">
          <Header>Contact</Header>
          <ContactByEmail />
          <ContactForm />
        </section>
      </Container>
    </>
  );
}

export default Contact;
